<template>
  <ion-page>
    <!-- Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="user-agreement" />
      <!-- Content Container -->
      <div class="app-max-width">
        User Agreement here.  
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from '@ionic/vue';
import ModalHeader from '../../components/ModalHeader.vue';
import { defineComponent } from 'vue';
import doAuth from '../../services/auth';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'UserAgreement',
  props: [],
  components: { IonPage, IonContent, ModalHeader },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    function goBack() {
      router.go(-1)
    }

    return {
      store, goBack, router
    }
  },
});
</script>